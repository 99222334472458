import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

import { getConditionsINedded, changeConditionId } from "redux/actions/setConditionActions";
import { getSchoolsINedded, getSchoolINedded } from "redux/actions/setSchoolActions";
import { getClassINedded } from "redux/actions/setClassActions";
import { stepUp, stepDown, setStep } from "redux/actions/setTestActions";
import { showAlert, hideAlert } from "redux/actions/setAppActions";
import fetch from "redux/fetch";
import { STEP_RESET } from "redux/types";
import { ProgressBar } from "components/ProgressBar";
import { Info } from "components/Info";
import { Layout } from "components/Layout";
import { TestsStep } from "pages/tests/TestsStep";
import { SchoolsStep } from "pages/tests/SchoolsStep";
import { ClassesStep } from "pages/tests/ClassesStep";
import { PupilsStep } from "pages/tests/PupilsStep";
/**
 * @api {get} /tests/create отправка теста ученику
 * @apiGroup tests
 * @apiName отправка теста ученику
 *
 * @apiDescription <p>Страница отправки теста ученику</p>
 * <p>Компонент <code>CreateTestPage.js</code> <a href="../client/src/pages/CreateTestPage.js">ссылка на файл</a></p>
 */

/**
 * @api {get} /tests/create/:id отправка теста по id
 * @apiGroup tests
 * @apiName отправка теста по id
 *
 * @apiParam {String} id ID теста
 *
 * @apiDescription <p>Страница отправки теста ученику по id теста</p>
 * <p>Компонент <code>CreateTestPage.js</code> <a href="../client/src/pages/CreateTestPage.js">ссылка на файл</a></p>
 */

export const CreateTestPage = () => {
    const history = useHistory();
    const { t } = useTranslation("tests");
    const { id } = useParams();
    const { search } = useLocation();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { step, conditions, conditionId } = useSelector((state) => state.test);
    const { schools, schoolData, classData } = useSelector((state) => state.school);
    const { loading } = useSelector((state) => state.app);
    const [selectAll, setSelectAll] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [pupils, setPupils] = useState([]);
    const [filteredPupils, setFilteredPupils] = useState([]);
    const [info, setInfo] = useState(null);
    const { filters } = useSelector((state) => state.createTest);
    const [classId, setClassId] = useState(null);

    useEffect(() => {
        dispatch(getConditionsINedded());
        dispatch(getSchoolsINedded());
    }, [dispatch]);

    useEffect(() => {
        if (classData?._id) {
            console.log(classData._id);
            setClassId(classData._id);
        }
    }, [classData]);

    useEffect(() => {
        setSelectAll(false);
        setPupils([]);
    }, [step]);

    useEffect(() => {
        (async () => {
            if (classId) {
                const pupilsData = await fetch(`/api/classes/${classId}/pupils`, { method: "GET" });
                let pupils = [];

                if (pupilsData?.length) {
                    pupils = pupilsData.filter(({ isArchive }) => !isArchive);
                }

                if (pupils) {
                    if (filters.sex) {
                        let filteredPupils = [];
                        for (let i = 0; i < pupils.length; i++) {
                            if (pupils[i].sex === filters.sex) {
                                filteredPupils.push(pupils[i]);
                            }
                        }
                        pupils = filteredPupils;
                    }

                    if (filters.years) {
                        let targetAges = filters.years.replace(/\s/g, "").split(",");
                        let filteredPupils = [];
                        for (let i = 0; i < pupils.length; i++) {
                            let currentYears = pupils[i].birthday;
                            let date = new Date(currentYears);
                            let ageDateNumber = Date.now() - date;
                            let ageDate = new Date(ageDateNumber);
                            let year = ageDate.getUTCFullYear();
                            let age = Math.abs(1970 - year);
                            for (let j = 0; j < targetAges.length; j++) {
                                if (targetAges[j].toString() === age.toString()) {
                                    filteredPupils.push(pupils[i]);
                                }
                            }
                        }
                        pupils = filteredPupils;
                    }

                    if (filters.category) {
                        let filteredPupils = [];
                        for (let i = 0; i < pupils.length; i++) {
                            for (let j = 0; j < pupils[i].categories.length; j++) {
                                if (pupils[i].categories[j] === filters.category) {
                                    filteredPupils.push(pupils[i]);
                                }
                            }
                        }
                        pupils = filteredPupils;
                    }
                }

                setFilteredPupils(pupils);
            }
        })();
    }, [classId, filters]);

    useEffect(() => {
        if (id) {
            dispatch(changeConditionId(id));
            dispatch(setStep(2));
        }
    }, [dispatch, id]);

    const backBtnHandler = () => {
        const from = new URLSearchParams(search).get("from");

        if (id && step === 2) {
            dispatch(stepDown());

            if (from) {
                history.push(from);
            } else {
                history.goBack();
            }
        } else if (step < 2) {
            if (from) {
                history.push(from);
            } else {
                history.goBack();
            }
        } else {
            dispatch(stepDown());
        }
    };

    const conditionsHandler = (condition) => {
        dispatch(changeConditionId(condition._id));
        dispatch(stepUp());
    };

    const schoolsHandler = (evt) => {
        dispatch(getSchoolINedded(evt.currentTarget.id));
        dispatch(stepUp());
    };

    const classesHandler = (evt) => {
        dispatch(getClassINedded(evt.currentTarget.id));
        dispatch(stepUp());
    };

    const visibleHandler = () => {
        setIsVisible(!isVisible);
    };

    const checkboxHandler = (evt) => {
        const array = [...pupils];
        const index = array.indexOf(evt.target.name);

        if (index > -1) {
            array.splice(index, 1);
        } else {
            array.push(evt.target.name);
        }
        setSelectAll(false);
        setPupils(array);
    };

    const selectAllHandler = () => {
        if (selectAll) {
            setPupils([]);
            setSelectAll(false);
        } else {
            const array = [];
            for (let pupil of filteredPupils) {
                array.push(pupil._id);
            }
            setSelectAll(true);
            setPupils(array);
        }
    };

    const showInfoHandler = (e, condition) => {
        if (!info) {
            e.stopPropagation();

            setInfo({
                name: condition.name,
                desc: condition.message,
                conditionId: condition._id,
                author: condition.methodAuthor,
                desTeacher: condition.desTeacher,
                message: condition.message,
                scales: condition.scales,
            });
        } else {
            setInfo(null);
        }
    };

    const sendHandler = async () => {
        try {
            if (!pupils.length) return;

            const { data } = await axios.post("tests/create", {
                pupils,
                conditionId,
                classId: classData._id,
                schoolId: schoolData._id,
                isVisible,
            });

            dispatch(showAlert({ type: "success", text: data.message }));
            queryClient.invalidateQueries(["tests/assigned-tests-by-condition", conditionId, classData._id]);

            setTimeout(() => {
                dispatch(hideAlert());
                dispatch({ type: STEP_RESET });
            }, 2000);
        } catch (e) {
            if (e.response.data.message) {
                dispatch(showAlert({ type: "error", text: e.response.data.message }));
            } else {
                dispatch(showAlert({ type: "error", text: e.message }));
            }
        }
    };

    if (info) {
        return (
            <Layout>
                <Info
                    name={info.name}
                    desc={info.desc}
                    handler={showInfoHandler}
                    conditionId={info.conditionId}
                    author={info.author}
                    desTeacher={info.desTeacher}
                    message={info.message}
                    scales={info.scales}
                />
            </Layout>
        );
    }

    return (
        <Layout>
            <div className="page create-test">
                <header className="page__header">
                    <button
                        className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        onClick={backBtnHandler}
                    />
                    <p className="page__title">{t("send_test")}</p>
                    {step === 4 && (
                        <Link
                            to={`/tests/create/${classId}/filters`}
                            className={
                                !!Object.values(filters).filter((val) => !!val.length).length
                                    ? `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter icon-btn_filter_active`
                                    : `icon-btn page__icon-btn page__icon-btn_right icon-btn_filter`
                            }
                        >
                            {!!Object.values(filters).filter((val) => !!val.length).length && (
                                <span>
                                    {Object.values(filters).filter((val) => !!val.length).length}
                                    <div className="dot"></div>
                                </span>
                            )}
                        </Link>
                    )}
                </header>

                <ProgressBar step={step} total={5} color="red" />

                {/*{step === 2 && (*/}
                {/*    <Link*/}
                {/*        to={`/pupils?from=${pathname}`}*/}
                {/*        className="main-btn"*/}
                {/*        style={{ marginBottom: "2rem" }}*/}
                {/*    >*/}
                {/*        {t("find_pupil")}*/}
                {/*    </Link>*/}
                {/*)}*/}

                <TestsStep
                    step={step}
                    loading={loading}
                    conditions={conditions}
                    clickHandler={conditionsHandler}
                    infoBtnHandler={showInfoHandler}
                />
                <SchoolsStep step={step} loading={loading} schools={schools} clickHandler={schoolsHandler} />
                <ClassesStep loading={loading} step={step} schoolData={schoolData} clickHandler={classesHandler} />
                {step === 4 && (
                    <PupilsStep
                        step={step}
                        conditionId={conditionId}
                        classData={classData}
                        pupils={pupils}
                        loading={loading}
                        selectAllHandler={selectAllHandler}
                        checkboxHandler={checkboxHandler}
                        clickHandler={sendHandler}
                        visibleHandler={visibleHandler}
                        selectAll={selectAll}
                        isVisible={isVisible}
                        filteredPupils={filteredPupils}
                    />
                )}
            </div>
        </Layout>
    );
};
