import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";

import { showAlert } from "redux/actions/setAppActions";
import { BackButton } from "components/BackButton";
import { Layout } from "components/Layout";
import phoneIcon from "static/img/ic-phone.svg";
import "./modifications.css";

/**
 * @api {get} /suggestions оставление комментариев и предложений
 * @apiGroup profile
 * @apiName оставление комментариев и предложений
 *
 * @apiDescription <p>Страница оставления комментариев и предложений</p>
 * <p>Компонент <code>SuggestionsPage.js</code> <a href="../client/src/pages/SuggestionsPage.js">ссылка на файл</a></p>
 */

const initForm = { title: "", text: "" };

export const SuggestionsPageYanao = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("suggestions");
    const [form, setForm] = useState(initForm);

    const onSendClick = async (type) => {
        const data = { title: form.title.trim(), text: form.text.trim(), type };

        try {
            await axios.post("/suggestions", data);

            setForm(initForm);

            dispatch(showAlert({ type: "success", text: t("suggestions_created") }));
        } catch (err) {
            const text = err.response?.data?.message || t("something_went_wrong", { ns: "translation" });

            dispatch(
                showAlert({
                    type: "error",
                    text,
                })
            );
        }
    };

    const changeHandler = (event) => {
        const { name, value } = event.target;

        if (name === "title" && value.length > 70) {
            return;
        }

        setForm({ ...form, [name]: value });
    };

    return (
        <Layout>
            <div className="page">
                <header className="page__header">
                    <BackButton />
                    <p className="page__title">{t("suggestions_title")}</p>
                </header>

                <div className="school-page__wrapper">
                    <h3>{t("suggestions_title_create")}</h3>
                    <div className="input-field input-field_yellow">
                        <TextareaAutosize
                            className="textarea-autosize"
                            id="title"
                            name="title"
                            value={form.title}
                            placeholder={t("field_title")}
                            onChange={changeHandler}
                        />
                        <label htmlFor="number">{t("field_title")}</label>
                    </div>
                    <div className="input-field input-field_yellow">
                        <TextareaAutosize
                            className="textarea-autosize"
                            id="text"
                            name="text"
                            value={form.text}
                            onChange={changeHandler}
                            placeholder={t("field_text")}
                        />
                        <label htmlFor="letter">{t("field_text")}</label>
                    </div>

                    <div className="suggestions-page__contacts">
                        <img src={phoneIcon} alt="" />
                        <div className="suggestions-page__contacts__phones">
                            <a href={`tel:${t("suggestions_btn_phone_yanao_link")}`}>
                                {t("suggestions_btn_phone_yanao")}
                            </a>
                            <a href="mailto:help@testu.online">help@testu.online</a>
                        </div>
                    </div>

                    <p style={{ marginTop: 20, opacity: 0.8, color: "#122443" }}>{t("suggestions_text")}</p>
                </div>

                <button
                    onClick={() => onSendClick("suggestion")}
                    disabled={!form.title || !form.text}
                    className="main-btn main-btn_green"
                >
                    {t("send_suggestion_btn")}
                </button>

                <button
                    onClick={() => onSendClick("problem")}
                    disabled={!form.title || !form.text}
                    className="main-btn main-btn_orange"
                    style={{ marginTop: 15 }}
                >
                    {t("send_problem_btn")}
                </button>
            </div>
        </Layout>
    );
};
