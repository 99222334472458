import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { showAlert } from "redux/actions/setAppActions";
import { getCondition } from "redux/actions/setConditionActions";
import { FINISH_LOADING, START_LOADING } from "redux/types";
import { CONSTRUCTOR_LINK, API_URL } from "config";
import { Loader } from "components/Loader";
import { Layout } from "components/Layout";
import { RangeAnswers } from "components/test/RangeAnswers";
import { SelectAnswers } from "components/test/SelectAnswers";
import { FreeFormAnswers } from "components/test/FreeFormAnswers";

/**
 * @api {get} /tests/:testId/:conditionId?type=type вопросы теста
 * @apiGroup tests
 * @apiName вопросы теста
 *
 * @apiParam {String} testId ID теста
 * @apiParam {String} conditionId ID методики
 *
 * @apiQuery {String} type (опционально) если в качесте типа указан "view", вопросы будут
 * показаны в демонстрационном режиме
 *
 * @apiDescription <p>Страница с вопросами по тесту</p>
 * <p>Компонент <code>TestPage.js</code> <a href="../client/src/pages/TestPage.js">ссылка на файл</a></p>
 */

export const TestPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("tests");
    const history = useHistory();
    const location = useLocation();
    const { user, role } = useSelector((state) => state.auth);
    const { condition } = useSelector((state) => state.test);
    const { loading } = useSelector((state) => state.app);

    const [questionId, setQuestionId] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState(null);
    const [results, setResults] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [end, setEnd] = useState(false);
    const [isConfirmPossible, setIsConfirmPossible] = useState(false);
    const [isViewTest, setIsViewTest] = useState(false);
    const [startTime, setStartTime] = useState(null);

    const conditionId = useParams().conditionId;
    const testId = useParams().testId;

    useEffect(() => {
        if (location.search) {
            const type = new URLSearchParams(location.search).get("type");

            if (type === "view") {
                setIsViewTest(true);
            }
        }
    }, [location]);

    useEffect(() => {
        dispatch(getCondition(conditionId));
    }, [conditionId, dispatch]);

    const postSolution = useCallback(async () => {
        if (role === "psych") return;
        dispatch({ type: START_LOADING });

        const json = condition.body;

        for (const [i, question] of json.questions.entries()) {
            if (question.type === "text") {
                question.value = results[i][0];
            } else {
                for (const answer of question.answers) {
                    if (results[i].includes(answer.id)) {
                        answer.selected = true;
                    }
                }
            }
        }

        /* eslint-disable no-throw-literal */
        fetch(`${API_URL}solution`, {
            method: "POST",
            body: JSON.stringify(json),
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        })
            .then(async (res) => {
                if (!res.ok) {
                    const data = await res.json();
                    throw {
                        message: data.message,
                    };
                } else {
                    return res.json();
                }
            })
            .then(async (res) => {
                const result = await fetch(`${API_URL}tests/solution`, {
                    method: "POST",
                    body: JSON.stringify({
                        solution: res,
                        rawSolution: json,
                        testId,
                        normStatus: true,
                        completionTime: Math.round((Date.now() - startTime) / 1000),
                    }),
                    headers: { "Content-Type": "application/json" },
                    credentials: "include",
                });

                if (!result.ok) {
                    throw {
                        message: t("error_message"),
                    };
                }

                return result.json();
            })
            .then(() => {
                window.location.href = `/dashboard`;
                dispatch({ type: FINISH_LOADING });
            })
            .catch((e) => {
                const text = e.message || t("something_went_wrong", { ns: "translation" });
                dispatch({ type: FINISH_LOADING });
                dispatch(showAlert({ type: "error", text }));

                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition, user, results, testId, conditionId, dispatch]);

    useEffect(() => {
        !!end && postSolution();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [end]);

    useEffect(() => {
        if (condition && condition.body) {
            const body = condition.body;

            const qws = body.questions;
            setQuestions(qws);
        }
    }, [condition]);

    useEffect(() => {
        if (questions.length) {
            const qw = questions[questionId];

            if (qw.questionType === "MultiSelection") {
                setQuestion({
                    name: qw.name,
                    image: qw.image,
                    type: "multi",
                    quantity: qw.answerQuantity,
                    answers: qw.answers,
                });
            } else {
                setQuestion({
                    name: qw.name,
                    image: qw.image,
                    type: qw.type,
                    answers: qw.answers,
                });
            }

            if (results[questionId]) {
                setAnswers([...results[questionId]]);
            } else {
                if (["Range", "Text", "ReversedRange"].includes(qw.questionType)) {
                    setAnswers([""]);
                } else {
                    setAnswers(Array(qw.answers.length).fill(""));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conditionId, questionId, questions]);

    const confirmBtnHandler = () => {
        if (isConfirmPossible) {
            const updatedResults = [...results];
            updatedResults[questionId] = answers;

            setResults(updatedResults);

            if (updatedResults.length >= questions.length) {
                setEnd(true);
            } else {
                setQuestionId((prev) => prev + 1);
            }

            if (!startTime) {
                setStartTime(Date.now());
            }
        }

        if (isViewTest) {
            const nextQuestionId = questionId + 1;

            if (nextQuestionId >= questions.length) {
                history.goBack();
            } else {
                setQuestionId(nextQuestionId);
            }
        }
    };

    const backBtnHandler = () => {
        if (questionId > 0) {
            setQuestionId(questionId - 1);
        }
    };

    const setNewAnswer = (value) => {
        if (!isViewTest) {
            setAnswers([value]);
        }
    };

    useEffect(() => {
        setIsConfirmPossible(!!answers.join(""));
    }, [answers]);

    const changeAnswer = (value, index) => {
        if (!isViewTest) {
            let newAnswers = answers;

            if (newAnswers[index] === value) {
                newAnswers[index] = "";
            } else {
                if (question.type !== "multi") {
                    newAnswers = newAnswers.map((_, i) => (i === index ? value : ""));
                } else {
                    const countValues = answers.filter((answer) => answer !== "").length;

                    if (countValues < question.quantity) {
                        newAnswers[index] = value;
                    }
                }
            }

            setAnswers([...newAnswers]);
        }
    };

    const changeFreeFormAnswer = (e) => {
        if (!isViewTest) {
            setAnswers([e.target.value]);
        }
    };

    if (!question) return null;

    if (loading) {
        return <Loader />;
    }

    function goBack() {
        if (role === "pupil") {
            history.push(`/tests/instruction/${conditionId}`);
        } else {
            history.goBack();
        }
    }

    return (
        <Layout>
            {!end ? (
                <div className="page walkthrough">
                    <header className="page__header">
                        <button
                            onClick={goBack}
                            className="icon-btn page__icon-btn page__icon-btn_left icon-btn_back"
                        />
                        <div className="walkthrough__score">
                            <p>{questionId + 1}</p>
                            <p>{questions.length}</p>
                        </div>
                    </header>

                    <div className="progress-bar walkthrough__progress-bar">
                        <span
                            className="progress-bar__progress"
                            style={{
                                width: `${((questionId + 1) * 100) / questions.length}%`,
                            }}
                        />
                    </div>
                    <p className="walkthrough__title" dangerouslySetInnerHTML={{ __html: question.name || t("choose_one_option") }} />

                    {question.image && (
                        <div className="walkthrough__image">
                            <img src={`${CONSTRUCTOR_LINK}/api/static/images/${question.image}`} alt="" />
                        </div>
                    )}

                    {["Selection", "select"].includes(question.type) ? (
                        <div className="answers-wrapper">
                            <p className="answers-wrapper__prompt">{t("choose_answer")}</p>
                            <SelectAnswers
                                answers={answers}
                                question={question}
                                confirmBtnHandler={confirmBtnHandler}
                                backBtnHandler={backBtnHandler}
                                isBackBtnActive={questionId > 0}
                                changeAnswer={changeAnswer}
                                isConfirmPossible={isConfirmPossible || isViewTest}
                            />
                        </div>
                    ) : ["Range", "range", "ReversedRange"].includes(question.type) ? (
                        <RangeAnswers
                            answers={answers}
                            question={question}
                            confirmBtnHandler={confirmBtnHandler}
                            setAnswer={setNewAnswer}
                            isConfirmPossible={isConfirmPossible || isViewTest}
                            isBackBtnActive={questionId > 0}
                            backBtnHandler={backBtnHandler}
                        />
                    ) : ["Text", "text"].includes(question.type) ? (
                        <div className="answers-wrapper">
                            <p className="answers-wrapper__prompt">{t("free_form_answer")}</p>
                            <FreeFormAnswers
                                freeFormValue={answers[0]}
                                confirmBtnHandler={confirmBtnHandler}
                                backBtnHandler={backBtnHandler}
                                isBackBtnActive={questionId > 0}
                                changeFreeFormAnswer={changeFreeFormAnswer}
                                isConfirmPossible={isConfirmPossible || isViewTest}
                            />
                        </div>
                    ) : (
                        <div className="answers-wrapper">
                            <p className="answers-wrapper__prompt">
                                {t("select")} {question.quantity ? question.quantity : question.answers.length}{" "}
                                {t("select_from")} {question.answers.length}
                            </p>
                            <SelectAnswers
                                answers={answers}
                                question={question}
                                confirmBtnHandler={confirmBtnHandler}
                                backBtnHandler={backBtnHandler}
                                isBackBtnActive={questionId > 0}
                                changeAnswer={changeAnswer}
                                isConfirmPossible={isConfirmPossible || isViewTest}
                            />
                        </div>
                    )}
                </div>
            ) : null}
        </Layout>
    );
};
