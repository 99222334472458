import { useQuery } from "@tanstack/react-query";

import { fetchAssignedTestsByCondition } from "api/tests/fetchAssignedTestsByCondition";

/**
 * @param {string} conditionId
 * @param {string} classId
 */
export function useFetchAssignedTestsByCondition(conditionId, classId) {
    const { data, error, isLoading } = useQuery({
        queryKey: ["tests/assigned-tests-by-condition", conditionId, classId],
        queryFn: () => fetchAssignedTestsByCondition(conditionId, classId),
        enabled: !!conditionId && !!classId,
    });

    return {
        assignedTests: data,
        assignedTestsError: error,
        assignedTestsIsLoading: isLoading,
    };
}
