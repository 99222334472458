import axios from "axios";

import { catchAsync } from "api/catchAsync";

/**
 * @param {string} conditionId
 * @param {string} classId
 */
export async function fetchAssignedTestsByCondition(conditionId, classId) {
    return catchAsync(async () => {
        const response = await axios.get("tests/assigned-tests-by-condition", {
            params: { conditionId, classId },
        });

        return response.data;
    });
}
