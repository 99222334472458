import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getAlarms } from "redux/actions/setAlarmActions";
import { getMeetings } from "redux/actions/setMeetingActions";
import { getTestsCountByPsych } from "redux/actions/setTestActions";
import { getSchools } from "redux/actions/setSchoolActions";
import { classNames } from "helpers/classNames";
import { checkIosPwa } from "helpers/devices";
import { downloadFileInPWA } from "helpers/pwa";
import { Loader } from "components/Loader";
import { useFetchPupilsWithCategories } from "hooks/useQuery";

import infoIcon from "static/img/ic-info-black.svg";
import informationForParents from "static/docs/information_for_parents.pdf";
import consentForLegalRepresentatives from "static/docs/consent_for_legal_representatives.docx";
import psychInstruction from "static/docs/psych_instruction.pdf";
import { IS_PRODUCTION } from "config";
import { SupportButton } from "./SupportButton";
import { YanaoComponent } from "helpers/YanaoComponent";

const documents = {
    informationForParents: {
        file: informationForParents,
        fileName: "information_for_parents.pdf",
    },
    consentForLegalRepresentatives: {
        file: consentForLegalRepresentatives,
        fileName: "consent_for_legal_representatives.docx",
    },
    psychInstruction: {
        file: psychInstruction,
        fileName: "Инструкция_по_использованию_платформы_TestU.pdf",
    },
};

export const PsychProfile = ({ loading }) => {
    const dispatch = useDispatch();
    const { alarms } = useSelector((state) => state.alarm);
    const { schools } = useSelector((state) => state.school);
    const { meetings } = useSelector((state) => state.meeting);
    const { testsCount } = useSelector((state) => state.test);
    const { pupilsWithAllCategories } = useFetchPupilsWithCategories();
    const [riskPupilsCount, setRiskPupilsCount] = useState(0);
    const [alarmsLength, setAlarmsLength] = useState(0);
    const { t } = useTranslation("profile");

    useEffect(() => {
        dispatch(getMeetings());
        dispatch(getTestsCountByPsych());
        dispatch(getSchools());
        dispatch(getAlarms());
    }, [dispatch]);

    useEffect(() => {
        if (pupilsWithAllCategories?.length) {
            setRiskPupilsCount(pupilsWithAllCategories.length);
        }
    }, [pupilsWithAllCategories]);

    useEffect(() => {
        if (alarms.length) {
            const getAlarmsLength = alarms.filter(({ readAt }) => !readAt);

            if (getAlarmsLength.length) {
                setAlarmsLength(getAlarmsLength.length);
            }
        }
    }, [alarms]);

    const onClickHandler = async (file) => {
        const fileInfo = documents[file];

        if (!fileInfo) return;

        if (checkIosPwa()) {
            await downloadFileInPWA(fileInfo.file, fileInfo.fileName);
        } else {
            window.open(fileInfo.file, "_blank");
        }
    };

    return (
        <div className="profile-page__entities">
            <Link
                to="/alarms"
                className={classNames("profile-page__psych-alarm", {
                    "profile-page__psych-alarm_notice": alarmsLength,
                })}
            >
                <div>
                    <p>{t("help_requests")}</p>
                    <p>{t("show_who_needs_help")}</p>
                </div>
                <span>{alarmsLength}</span>
            </Link>

            <YanaoComponent componentName={"SupportButtonYanao"}>
                <SupportButton />
            </YanaoComponent>

            {loading ? (
                <Loader />
            ) : (
                <div className="profile-page__entities-wrapper" style={{ paddingBottom: 24 }}>
                    <Link to="/schools" className={`entity ${!!schools.length && "entity_school"}`}>
                        <p className={`entity__title ${!!schools.length && "entity__title_white"}`}>{t("schools")}</p>
                        {!schools.length && <p className="entity__description">{t("add_school")}</p>}
                        <span className={`entity__extra ${!schools.length && "entity__extra_psych-null"}`}>
                            {!!schools.length && schools.length}
                        </span>
                    </Link>
                    <Link to="/meetings" className={`entity ${meetings.length ? "entity_meeting" : null}`}>
                        <p className={`entity__title ${meetings.length ? "entity__title_white" : null}`}>
                            {t("meetings")}
                        </p>
                        {!meetings.length && <p className="entity__description">{t("add_meeting")}</p>}
                        <span className={`entity__extra ${!meetings.length ? "entity__extra_psych-null" : null}`}>
                            {meetings.length ? meetings.length : null}
                        </span>
                    </Link>
                    <Link to="/statistic/schools" className={`entity ${!!testsCount && "entity_statistic"}`}>
                        <p className={`entity__title ${testsCount ? "entity__title_white" : null}`}>
                            {t("psychodiagnostics_results")}
                        </p>
                        {!testsCount && <p className="entity__description">{t("no_data_for_analysis")}</p>}
                        {!!testsCount && <span className="entity__extra entity__extra_statistic"></span>}
                    </Link>
                    <Link to="/workWithMethods" className="entity entity_workWithMethods">
                        <p className="entity__title entity__title_white">{t("working_with_methods")}</p>

                        <span className="entity__extra entity__extra_workWithMethods"></span>
                    </Link>

                    {/* Рекомендации */}
                    {!IS_PRODUCTION && (
                        <Link to="/advices/schools" className="entity entity_recommendations">
                            <p className="entity__title entity__title_white">{t("recommendations")}</p>
                            <span className="entity__extra entity__extra_workWithMethods"></span>
                        </Link>
                    )}

                    <Link to="/riskGroups" className={`entity ${!!riskPupilsCount && "entity_riskGroups"}`}>
                        <p className={`entity__title ${riskPupilsCount ? "entity__title_white" : null}`}>
                            {t("risk_groups")}
                        </p>
                        <span className={`entity__extra ${!riskPupilsCount && "entity__extra_psych-null"}`}>
                            {!!riskPupilsCount && riskPupilsCount}
                        </span>
                    </Link>
                    <div className="entity entity_instruction" onClick={() => onClickHandler("psychInstruction")}>
                        <p className="entity__title entity__title_white">{t("instruction")}</p>
                    </div>
                    <Link to="/management-reporting" className="entity entity_meeting">
                        <p className="entity__title entity__title_white">{t("management_reporting")}</p>
                    </Link>
                    <Link to="/guidelines" className="entity entity_workWithMethods">
                        <p className="entity__title entity__title_white">{t("guidelines")}</p>
                    </Link>
                </div>
            )}

            <button
                onClick={() => onClickHandler("informationForParents")}
                className="profile-page__psych-parents-info"
            >
                <div>
                    <p>{t("information_btn_text")}</p>
                </div>
                <span>
                    <img src={infoIcon} alt="" />
                </span>
            </button>

            <button
                onClick={() => onClickHandler("consentForLegalRepresentatives")}
                className="profile-page__psych-parents-info"
            >
                <div>
                    <p>{t("consent_for_legal_representatives_btn_text")}</p>
                </div>
                <span>
                    <img src={infoIcon} alt="" />
                </span>
            </button>
        </div>
    );
};
